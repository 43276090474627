import { styled } from '@storis/app_common.ui/components';
import type { ButtonProps, IconButtonProps } from '@storis/app_common.ui/components';
import type React from 'react';
import { useRef } from 'react';
import CopyIconButton from './CopyIconButton';

interface CopyToClipboardButtonProps {
	/** Component used to customize the appearance of the button. */
	component?: React.FC<ButtonProps | IconButtonProps>;
	/** The text to be copied to the clipboard. */
	value: string | undefined;
	/**
	 * Optional tooltip to present for the button
	 *
	 * (default: undefined)
	 */
	tooltip?: string;
	/**
	 * Function to call when the text is copied to the clipboard.
	 *
	 * (default: undefined)
	 */
	onCopiedToClipboard?: () => void;
}

const TextAreaContainer = styled('textarea')({ position: 'fixed', left: '-9999px' });

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
	const {
		component: Component = CopyIconButton,
		value,
		tooltip,
		onCopiedToClipboard,
		...rest
	} = props;
	const textInput = useRef<HTMLTextAreaElement>(null);

	const onClick = () => {
		// when value is null, there is no value to copy
		// we will disable the component or copy button to make it non-clickable.
		if (textInput.current != null) {
			textInput.current.disabled = false;
			textInput.current.select();
			document.execCommand('copy');
			textInput.current.disabled = true;
			if (onCopiedToClipboard != null) {
				onCopiedToClipboard();
			}
		}
	};

	return (
		<div>
			<TextAreaContainer aria-hidden="true" value={value} disabled ref={textInput} />
			<Component onClick={onClick} tooltip={tooltip} {...rest} disabled={value == null} />
		</div>
	);
};

export default CopyToClipboardButton;
