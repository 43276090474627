import { IconButton, Tooltip } from '@storis/app_common.ui/components';
import type { IconButtonProps } from '@storis/app_common.ui/components';
import { FileCopyIcon } from '@storis/app_common.ui/icons';

interface CopyIconButtonProps extends IconButtonProps {
	onClick: () => void;
	tooltip?: string;
}

const CopyIconButton = (props: CopyIconButtonProps) => {
	const { onClick, tooltip = '', ...rest } = props;
	return (
		<Tooltip title={tooltip} placement="right">
			<IconButton sx={{ width: 'unset', height: 'unset' }} onClick={onClick} {...rest} size="large">
				<FileCopyIcon sx={{ fontSize: 20 }} />
			</IconButton>
		</Tooltip>
	);
};

export default CopyIconButton;
