import { Button } from '@storis/app_common.ui/components';
import type { ButtonProps } from '@storis/app_common.ui/components';
import { FileCopyIcon } from '@storis/app_common.ui/icons';

const MoreInfoCopyButton = (props: ButtonProps) => (
	<Button color="primary" {...props} startIcon={<FileCopyIcon />}>
		Copy
	</Button>
);

export default MoreInfoCopyButton;
